import PropTypes from 'prop-types';
import React from 'react';

function Header() {
	return (
		<nav className="navbar is-primary">
			<div className="navbar-brand">
				<a className="navbar-item" href="/">
					Drinktracker
				</a>
			</div>
		</nav>
	);
}

Header.propTypes = {
	siteTitle: PropTypes.string,
};

export default Header;
