import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { graphql } from 'gatsby';

Index.propTypes = {
	data: PropTypes.shape({
		heroImage: PropTypes.shape({
			childImageSharp: PropTypes.shape({
				fluid: PropTypes.object,
			}),
		}),
	}),
};

export default function Index({ data }) {
	return (
		<Layout>
			<SEO />
			<section className="hero is-light">
				<div className="hero-body">
					<div className="container">
						<div className="columns is-vcentered">
							<div className="column">
								<h1 className="title">
									Track your drinks. Keep full control of your night.
								</h1>
								<h2 className="subtitle">
									Our app provides you with helpful cues and stats designed to
									help you moderate and stay on track. No more hangovers.
								</h2>
								<a
									href="https://app.drinktracker.app"
									className="button is-primary is-large"
								>
									Start tracking for free →
								</a>
							</div>
							<div className="column">
								<div className="columns is-centered is-mobile">
									<div className="column is-two-thirds-mobile is-half-tablet">
										<Img
											fluid={data.heroImage.childImageSharp.fluid}
											alt="Drinktracker with a few drinks added."
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}

export const query = graphql`
	query {
		heroImage: file(name: { eq: "hero" }) {
			childImageSharp {
				fluid(maxWidth: 470) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
